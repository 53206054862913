import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Icon, Strong, List, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDoneAll } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Knockout Academy
			</title>
			<meta name={"description"} content={"Knockout Academy'de birinci sınıf boks eğitimini keşfedin. Uzman koçlarımızla becerilerinizi geliştirin, formunuzu iyileştirin ve hedeflerinize ulaşın."} />
			<meta property={"og:title"} content={"Knockout Academy"} />
			<meta property={"og:description"} content={"Knockout Academy'de birinci sınıf boks eğitimini keşfedin. Uzman koçlarımızla becerilerinizi geliştirin, formunuzu iyileştirin ve hedeflerinize ulaşın."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Headernew />
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-16"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 60px 0px"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text as="p" font="--base" color="--orange" margin="0px 0px 8px 0px">
					Yetenekli Ekibimiz
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					Hizmetlerimiz
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="70%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="center"
					sm-text-align="left"
				>
					Knockout Academy'de, her müşteriye olağanüstü bir bakım deneyimi sunmaya kararlıyız. Yetkin boksörlerimiz, kişisel ihtiyaçlarınıza uygun hassas, şık ve kişiselleştirilmiş antrenman ve bakım hizmetleri sunmayı hedeflemektedir. Her ziyaretin size enerji vermesi, özgüveninizi artırması ve en iyi şekilde görünmenizi sağlaması gerektiğine inanıyoruz.
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="70%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="center"
					sm-text-align="left"
				>
					Boksörlerimiz sadece profesyonel değil, aynı zamanda işlerine tutkuyla bağlı sanatçılardır. Ekibimizin her üyesi, her müşteriye en yüksek kalitede hizmet sunmak için benzersiz bir yetenek ve deneyim setine sahiptir. Detaylara dikkat ve sürekli öğrenmeye bağlılıklarıyla boksörlerimiz, size en iyi deneyimi sunmak için en son trendleri ve teknikleri takip etmektedir.
				</Text>
			</Box>
			<Box
				display="flex"
				width="100%"
				lg-justify-content="center"
				lg-width="100%"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				justify-content="center"
				margin="0px 0px 36px 0px"
				position="relative"
				background="rgba(0, 0, 0, 0) linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%) 0% 0% /auto repeat scroll padding-box"
			>
				<Image
					src="https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/1-3.jpg?v=2024-08-01T08:54:09.144Z"
					display="block"
					margin="0px 0px 0px 0px"
					border-radius="16px"
					srcSet="https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/1-3.jpg?v=2024-08-01T08%3A54%3A09.144Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/1-3.jpg?v=2024-08-01T08%3A54%3A09.144Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/1-3.jpg?v=2024-08-01T08%3A54%3A09.144Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/1-3.jpg?v=2024-08-01T08%3A54%3A09.144Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/1-3.jpg?v=2024-08-01T08%3A54%3A09.144Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/1-3.jpg?v=2024-08-01T08%3A54%3A09.144Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/1-3.jpg?v=2024-08-01T08%3A54%3A09.144Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<List
				margin="40px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				sm-margin="24px 0 0 0"
				list-style-type="none"
				font="normal normal 18px/150% sans-serif"
				display="grid"
				flex-direction="column"
				grid-gap="25px"
				lg-margin="32px 0px 0px 0px"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
			>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdDoneAll}
						size="40px"
						color="--primary"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Boks Dersleri
							<br />
						</Strong>
						<br />
						Her beceri seviyesine uygun grup oturumları, teknik, kondisyon ve stratejiye odaklanır.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdDoneAll}
						size="40px"
						color="--primary"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Kişisel Antrenman
							<br />
						</Strong>
						<br />
						Kendi hedeflerinize özel olarak tasarlanmış birebir seanslar.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdDoneAll}
						size="40px"
						color="--primary"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						font="18px/27px --fontFamily-sans"
						color="--light"
						lg-max-width="720px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Fitness Programları
							<br />
							<br />
						</Strong>
						Boks ilhamlı egzersizlerle genel kondisyonunuzu artırın.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdDoneAll}
						size="40px"
						color="--primary"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/\n\n27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Gençlik Eğitimi
							<br />
							<br />
						</Strong>
						Genç sporcular için özel programlar, yeteneklerini geliştirmelerine ve özgüven kazanmalarına yardımcı olur.
					</Text>
				</Box>
			</List>
			<Text
				as="p"
				margin="3rem 0 0 0"
				font="--lead"
				font-size="20px"
				font-weight="300"
				color="--light"
				width="100%"
				md-width="100%"
				md-margin="0px 0px 0px 0px"
				md-padding="0px 0px 0px 0px"
				sm-font="--base"
				text-align="center"
				sm-text-align="left"
			>
				Knockout Academy'de, tüm bakım ihtiyaçlarınızı karşılayacak geniş bir hizmet yelpazesi sunuyoruz. Tekliflerimiz, geleneksel ve modern saç kesimlerinden, lüks traş, sakal bakımı ve daha fazlasını içerir. Her hizmet, en iyi sonuçları sağlamak için dikkatle ve premium ürünler kullanılarak gerçekleştirilir.
			</Text>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/1-4.jpg?v=2024-08-01T08:54:09.209Z) center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Ve bu sadece başlangıç!
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Knockout Academy'de, tüm bakım ihtiyaçlarınızı karşılayacak geniş bir hizmet yelpazesi sunuyoruz. Tekliflerimiz hakkında daha fazla bilgi edinmek için bizi ziyaret etmeye veya bizimle iletişime geçmeye davetlisiniz. Dostane ekibimiz her zaman size yardımcı olmaya ve en iyi hizmeti sağlamaya hazırdır.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					İletişim
				</Link>
			</Box>
		</Section>
		<Components.Footernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});